import { batchActions } from 'redux-batched-actions';

import {
    SEARCH_RESULT_LANGUAGES,
    SEARCH_RESULT_FILTER_TYPES,
    SEARCH_RESULT_ITEMS_PER_PAGE,
} from '../utils/constants';
import { processGalleryItems } from '../utils/responseProcessing';
import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import urls from '../apiUrls';


export const CHANGE_SEARCH_RESULT_ITEMS_ORDERING = 'CHANGE_SEARCH_RESULT_ITEMS_ORDERING'
export const CLEAR_ALREADY_RECEIVED_ITEMS = 'CLEAR_ALREADY_RECEIVED_ITEMS'
export const INCREMENT_SEARCHING_OFFSET = 'INCREMENT_SEARCHING_OFFSET'
export const RESET_SEARCH_RESULT_ITEMS = 'RESET_SEARCH_RESULT_ITEMS'
export const SET_ERROR_SEARCH_RESULT_ITEMS = 'SET_ERROR_SEARCH_RESULT_ITEMS'
export const SET_GAME_VERSION_FILTER = 'SET_GAME_VERSION_FILTER'
export const SET_LANGUAGE_FILTER = 'SET_LANGUAGE_FILTER'
export const START_SEARCHING_ITEMS = 'START_SEARCHING_ITEMS'
export const STOP_SEARCHING_ITEMS = 'STOP_SEARCHING_ITEMS'
export const UPDATE_SEARCH_RESULT_ITEMS = 'UPDATE_SEARCH_RESULT_ITEMS'

export const startSearchingItems = () => ({
    type: START_SEARCHING_ITEMS,
})

export const stopSearchingItems = () => ({
    type: STOP_SEARCHING_ITEMS,
})

export const updateSearchResultItems = (filterParams, rawResponseData, state) => ({
    type: UPDATE_SEARCH_RESULT_ITEMS,
    payload: {
        items: processGalleryItems(rawResponseData, state),
        count: rawResponseData.count,
        filterParams,
    },
})

export const setErrorSearchResultItems = (errorData) => ({
    type: SET_ERROR_SEARCH_RESULT_ITEMS,
    payload: {
        errorData,
    },
})

export const incrementSearchingOffset = () => ({
    type: INCREMENT_SEARCHING_OFFSET,
})

export const resetSearchResultItems = () => ({
    type: RESET_SEARCH_RESULT_ITEMS,
})

export const changeSearchResultItemsOrdering = (ordering) => ({
    type: CHANGE_SEARCH_RESULT_ITEMS_ORDERING,
    payload: {
        ordering,
    },
})

const prepareQueryParamsForSearching = (filterParams, state) => {
    const { type, argument } = filterParams
    let query
    
    switch (type) {
        case SEARCH_RESULT_FILTER_TYPES.OWNER: {

            query = {
                limit: SEARCH_RESULT_ITEMS_PER_PAGE,
                offset: state.searchPage.filtered.offset,
                ordering: filterParams.ordering,
                owner_id: argument,
                
                count: 0,
            }
            break
        }
        case SEARCH_RESULT_FILTER_TYPES.TAGS: {
            
            query = {
                limit: SEARCH_RESULT_ITEMS_PER_PAGE,
                offset: state.searchPage.filtered.offset,
                ordering: filterParams.ordering,
                tags: argument,

                count: 0,
            }
            break
        }
        case SEARCH_RESULT_FILTER_TYPES.TITLE: {
            
            query = {
                limit: SEARCH_RESULT_ITEMS_PER_PAGE,
                offset: state.searchPage.filtered.offset,
                ordering: filterParams.ordering,
                query: argument,

                count: 0,
            }
            break
        }
        default: { 
            query = {
                limit: SEARCH_RESULT_ITEMS_PER_PAGE,
                offset: state.searchPage.filtered.offset,
                ordering: filterParams.ordering,
                query: argument,

                count: 0,
            }
            break
        }
    }

    if (state.searchPage.overallFilters.language !== SEARCH_RESULT_LANGUAGES.ALL) {
        query = { ...query, language: state.searchPage.overallFilters.language }
    }

    if (state.searchPage.overallFilters.gameVersion !== 'all') {
        query.game_version_id = state.searchPage.overallFilters.gameVersion
    }
    
    return query
}

export const fetchItems = (filterParams) => {
    return (dispatch, getState) => {
        const state = getState()
        const url = `https://tankimods.net`+urls.search //urls.mods+`search/`

        dispatch(startSearchingItems())

        const query = prepareQueryParamsForSearching(filterParams, state)
        const fetchPromise = fetch(url, { query }).promise 
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorSearchResultItems(rawResponseData.context),
                        stopSearchingItems(),
                    ], SET_ERROR_SEARCH_RESULT_ITEMS))
                } else {
                    dispatch(batchActions([
                        updateSearchResultItems(filterParams, rawResponseData.body, state),
                        stopSearchingItems(),
                    ], UPDATE_SEARCH_RESULT_ITEMS))
                }
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorSearchResultItems(errorData),
                    stopSearchingItems(),
                ], SET_ERROR_SEARCH_RESULT_ITEMS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const setLanguageFilter = (language) => ({
    type: SET_LANGUAGE_FILTER,
    payload: {
        language,
    },
})

export const setGameVersionFilter = (gameVersionId) => ({
    type: SET_GAME_VERSION_FILTER,
    payload: {
        gameVersionId,
    },
})

export const changeLanguageFilter = (language) => {
    return console.log(language)
    /*return (dispatch, getState) => {
        const state = getState()

        dispatch(batchActions([
            setLanguageFilter(language),
            resetSearchResultItems(),
        ], SET_LANGUAGE_FILTER))

        dispatch(fetchItems(state.searchResult.filtered.filterParams))
    }*/
}

export const changeGameVersionFilter = (gameVersionId) => {
    return console.log(gameVersionId)
    /*return (dispatch, getState) => {
        const state = getState()

        dispatch(batchActions([
            setGameVersionFilter(gameVersionId),
            resetSearchResultItems(),
        ], SET_GAME_VERSION_FILTER))

        dispatch(fetchItems(state.searchResult.filtered.filterParams))
    }*/
}
