import React from 'react';
import { keys, unionBy } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from "../../components/WithRouter/WithRouter";

import settings from '../../settings';

import { 
    addChangelogHistoryItem,
    setChangelogHistoryOrdering, 
    editChangelogHistoryItem 
} from '../../actions/ChangelogHistory';
import { openDialog } from '../../actions/Dialog';
import { 
    changeModStatus,
    fetchEditDetails,
    fetchEditComments,
    changeModVisibility,
    publishMod,
    changeModVersionVisibility,
    setModVersionUploadFormVisibility, 
    rejectMod
} from '../../actions/EditMod';

import ReviewMod from '../../components/ReviewMod/ReviewMod';

import DialogConfirmationChangelogHistoryItemRemove 
    from '../DialogConfirmationChangelogHistoryItemRemoveWrapper/DialogConfirmationChangelogHistoryItemRemoveWrapper';
import DialogConfirmationModRemove
    from '../DialogConfirmationModRemoveWrapper/DialogConfirmationModRemoveWrapper';
import DialogEdgeAlert
    from '../DialogEdgeAlertWrapper/DialogEdgeAlertWrapper';
import DialogIEAlert
    from '../DialogIEAlertWrapper/DialogIEAlertWrapper';
import DialogConfirmationModVersionRemove
    from '../DialogConfirmationModVersionRemoveWrapper/DialogConfirmationModVersionRemoveWrapper';
import { openDialogError } from '../../actions/DialogError';
import DialogShowUserData from '../DialogShowUserDataWrapper/DialogShowUserDataWrapper';

const mapStateToProps = (state) => ({
    categories: keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title })),
    changelogHistory: state.changelogHistory.items,
    comments: state.editMod.comments,
    data: state.editMod.data,
    gameVersions: unionBy(settings.unreleasedGameVersions, settings.gameVersions, 'id'),
    isError: state.editMod.isError,
    isErrorComments: state.editMod.isErrorComments,
    isErrorForbidden: state.editMod.isErrorForbidden,
    isErrorNotFound: state.editMod.isErrorNotFound,
    isFetched: state.editMod.isFetched,
    isFetchedComments: state.editMod.isFetchedComments,
    isFetching: state.editMod.isFetching,
    isEdgeAlertAccepted: state.persistedValues.isEdgeAlertAccepted,
    isIEAlertAccepted: state.persistedValues.isIEAlertAccepted,
    isModVersionUploadFormVisible: state.editMod.isModVersionUploadFormVisible,
    isUserBanned: state.currentAccount.isBanned,
    isUserLoggedIn: !!state.currentAccount.spaId,
    isUserStatisticsSufficient: !!state.currentAccount.isUserStatisticsSufficient, // было без ! знаков
    language: state.currentAccount.lang,
    realm: state.currentAccount.realm,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onComponentWillMount: (modId, ownerId, role) => {
        dispatch(fetchEditComments(modId, 1)) // второй параметр - код комментария: 1 - коммент из раздела создания модов, 0 - обычный коммент
        dispatch(fetchEditDetails(modId, ownerId, role))
    },
    onModStatusChange: (modId, status) => {
        dispatch(changeModStatus(modId, status))
    },
    onModVersionRemove: (modVersionId) => {
        dispatch(openDialog(<DialogConfirmationModVersionRemove id={modVersionId} />))
    },
    onModVersionVisibilityChange: (modVersionId, isVisible) => {
        dispatch(changeModVersionVisibility(modVersionId, isVisible))
    },
    onModVisibilityChange: (modId, isVisible) => {
        dispatch(changeModVisibility(modId, isVisible))
    },
    onModRemove: (modId, isDraftOrRejected) => {
        dispatch(openDialog(<DialogConfirmationModRemove id={modId} isDraftOrRejected={isDraftOrRejected} />))
    },
    onSetModVersionUploadFormVisibility: (isVisible) => {
        dispatch(setModVersionUploadFormVisibility(isVisible))
    },
    onShowConfirmationHistoryDialog: () => {
        dispatch(openDialog(<DialogConfirmationChangelogHistoryItemRemove />))
    },

    onChangelogConfirmationDialogShow: (id) => {
        dispatch(openDialog(<DialogConfirmationChangelogHistoryItemRemove id={id} />))
    },
    onChangelogHistoryItemAdd: (content, version) => {
        dispatch(addChangelogHistoryItem(content, version))
    },
    onChangelogHistoryItemEdit: (id, position, content, version) => {
        dispatch(editChangelogHistoryItem(id, position, content, version))
    },
    onChangelogHistoryItemsReordered: (orderedIds) => {
        dispatch(setChangelogHistoryOrdering(orderedIds))
    },
    onFormSubmit: (modId, data) => {
        dispatch(publishMod(modId, data, ownProps.history))
    },
    onShowEdgeConfirmationDialog: () => {
        dispatch(openDialog(<DialogEdgeAlert />))
    },
    onShowIEConfirmationDialog: () => {
        dispatch(openDialog(<DialogIEAlert />))
    },

    onGetUserData: (ownerId) => {
        dispatch(openDialog(<DialogShowUserData ownerId={ownerId} />))
    },

    onModPublished: (modId) => {
        //dispatch(changeModStatus(modId, status))
    },
    onModRejected: (modId, data, msg) => {
        dispatch(rejectMod(modId, data, msg, ownProps.history))
    },
    onShowDialogError: (title, content) => {
        dispatch(openDialogError(content, title))
    },
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReviewMod)
)
