import apiUrls from "../apiUrls";
import { batchActions } from 'redux-batched-actions';
import { fetchWrapper as fetch, fetchWithSpinner } from "../utils/fetch";
import { ACCESS_DATA, signIn, signUp, toProlongate, toSignIn, updAccessData, UPDATE_ACCOUNT } from "./CurrentAccount";

export const SET_ERROR_LOGIN = 'SET_ERROR_LOGIN'


export const setErrorLogin = (errorData) => ({
    type: SET_ERROR_LOGIN,
    payload: {
        errorData,
    },
})

export const login = (realm) => {
    const url = `https://tankimods.net`;

    return window.location = url+apiUrls.login[realm] // url+apiUrls.login[realm]  // url+'/auth/login/local/'   .login['localhost']
}


export const signin = (login, password) => {
    return (dispatch, getState) => {
        const state = getState()

        const url = `https://tankimods.net`+apiUrls.login['admin'] //'https://tankimods.net/auth/login/admin/';

        //dispatch(startLogin())

        const fetchPromise = fetch(url, {method: 'POST', body: {login, password}}).promise
            .then((rawResponseData) => {
                // && rawResponseData.context.msg === 'auth__acc_wgid_not_found_in_bd'
                if (rawResponseData.status === 'error') {
                    dispatch(signIn({isErrorData: true}, state))
                } else {
                    dispatch(batchActions([
                        signIn({isErrorData: false}, state),
                        toProlongate(rawResponseData)
                    ], UPDATE_ACCOUNT))
                }
            })
            .catch((errorData) => {
                dispatch(signIn({isErrorData: null}, state))
            })
        return fetchWithSpinner(dispatch, fetchPromise)//
    }
}

export const signup = (login, password, accessName, openkey) => {
    return (dispatch, getState) => {
        const state = getState()

        const url = `https://tankimods.net`+apiUrls.login['admin'] //'https://tankimods.net/auth/login/admin/';

        const fetchPromise = fetch(url, {method: 'PUT', body: {login, password, accessName, openkey}}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(signUp({isErrorData: true}, state))
                } else {
                    dispatch(batchActions([
                        signUp({isErrorData: false}, state),
                        toSignIn(rawResponseData)
                    ], UPDATE_ACCOUNT))
                }
            })
            .catch((errorData) => {
                dispatch(signUp({isErrorData: null}, state))
            })
        return fetchWithSpinner(dispatch, fetchPromise)//
    }
}

export const checkKey = (accessKey) => {
    return (dispatch, getState) => {
        const state = getState()

        const url = `https://tankimods.net`+apiUrls.login['admin'] //'https://tankimods.net/auth/login/admin/';

        const fetchPromise = fetch(url, {method: 'PATCH', body: {accessKey}}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(signIn({isErrorData: true}, state))
                } else if (rawResponseData.accessKey === false) {
                    dispatch(signIn({isErrorData: true}, state))
                } else {
                    dispatch(batchActions([
                        signIn({isErrorData: !rawResponseData.accessKey}, state),
                        updAccessData(rawResponseData, state)
                    ], ACCESS_DATA))
                }
            })
            .catch((errorData) => {
                dispatch(signIn({isErrorData: null}, state))
            })
        return fetchWithSpinner(dispatch, fetchPromise)//
    }
}
