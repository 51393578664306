import { get, intersection, isEmpty, keys, last, orderBy } from 'lodash';

import { snakeFieldsToCamel } from './strings';
import { MOD_VERSION_STATUS, MOD_STATE } from './constants';


export const getModTitle = (rawItem, currentLanguageCode) => {
    if (isEmpty(rawItem.localizations)) {
        // eslint-disable-next-line no-console
        console.warn('No localizations found for mod', rawItem.id)
        return '??No title??'
    }
    
    const filteredData = rawItem.localizations.filter((item) => item.lang === currentLanguageCode)
    //const filteredData = rawItem.localizations.filter((item) => item.lang.code === currentLanguageCode)
    return isEmpty(filteredData) ? rawItem.localizations[0].title : filteredData[0].title
}

const getTagsData = (tagIds, state) => {
    const stateTagIds = keys(state.tags).map((id) => +id)
    const intersectedIds = intersection(tagIds, stateTagIds)
    return intersectedIds.map((tagId) => ({ id: tagId, caption: state.tags[tagId].title }))
}

export const processGalleryItems = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    try {
        return get(rawResponseData, 'results', []).map((rawItem) => {
            const sortedVersions = orderBy(rawItem.versions, ['id'], ['desc'])
            return {
                id: rawItem.id,
                downloads: rawItem.downloads,
                authorName: rawItem.author_name,
                cover: rawItem.cover_catalog,
                createdAt: rawItem.createdAt,
                updatedAt: rawItem.updatedAt,
                gameVersion: sortedVersions[0].game_version.version,
                mark: parseFloat(rawItem.mark),
                modVersion: sortedVersions[0].version,
                ownerId: rawItem.owner_id, // owner.pk
                ownerName: rawItem.spa_username, // owner.spa_username
                tags: getTagsData(rawItem.tags, state),
                title: getModTitle(rawItem, currentLanguageCode), // rawItem.title,
                versions: sortedVersions.map((item) => ({
                    downloadUrl: item.download_url,
                    gameVersion: item.game_version.version,
                    gameVersionId: item.game_version.id,
                    id: item.id,
                    modVersion: item.version,
                    versionFileOriginalName: last(item.version_file_original_name.split('/')),
                    versionFileSize: item.version_file_size,
                })),
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processSubscriptions = (rawResponseData, state) => {
    const languageCurrentCode = state.locale.language
    try {
        return rawResponseData.map((rawItem) => {
            const sortedVersions = orderBy(rawItem.mod.versions, ['id'], ['desc'])
            return {
                id: rawItem.id,
                isUpdated: rawItem.is_updated,
                mod: {
                    cover: rawItem.mod.cover_catalog,
                    id: rawItem.mod.id,
                    mark: parseFloat(rawItem.mod.mark),
                    modVersion: get(sortedVersions, '[0].version'),
                    title: getModTitle(rawItem.mod, languageCurrentCode), //rawItem.mod.title,
                    versions: sortedVersions.map((item) => ({
                        createdAt: get(item, 'created_at'),
                        downloadUrl: item.download_url,
                        id: item.id,
                        modVersion: item.version,
                        versionFileOriginalName: last(item.version_file_original_name.split('/')),
                        versionFileSize: item.version_file_size,
                    })),
                },
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processNews = (rawResponseData) => {
    //let responseData = rawResponseData.data.body;
    try {
        return rawResponseData.map((rawItem) => ({
            id: rawItem.id,
            content: rawItem.content,
            title: rawItem.title,
            publishedAt: rawItem.published_at,
            img: rawItem.img,
            url: rawItem.url,
            changeLog: rawItem.change_log,
            category: rawItem.category
        }))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
    }
}

export const processNotifications = (rawResponseData) => {
    try {
        return rawResponseData.map((rawItem) => ({
            id: rawItem.id,
            author: rawItem.author,
            modId: rawItem.mod_id,
            title: rawItem.title,
            spaId: rawItem.spa_id,
            isUpdated: rawItem.is_updated,
            createdAt: rawItem.created_at,
            content: rawItem.content, // for moderator info
        }))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
    }
}

export const processAdminPanelItems = (rawResponseData, state) => {
    const currentLanguageCodes = state.locale.language
    try {
        return get(rawResponseData, 'results', []).map((rawItem) => {
            const sortedVersions = orderBy(rawItem.mod.versions, ['id'], ['desc'])
            return {
                id: rawItem.id,
                isUpdated: rawItem.is_updated,
                mod: {
                    cover: rawItem.mod.cover_catalog,
                    id: rawItem.mod.id,
                    state: rawItem.mod.state,
                    mark: parseFloat(rawItem.mod.mark),
                    modVersion: get(sortedVersions, '[0].version'),
                    title: getModTitle(rawItem.mod, currentLanguageCodes),
                    versions: sortedVersions.map((item) => ({
                        createdAt: get(item, 'created_at'),
                        gameVersion: get(item, 'game_version.version'),
                        gameVersionId: get(item, 'game_version.id'),
                        id: item.id,
                        modVersion: item.version,
                        versionFile: item.version_file,
                        downloadUrl: item.download_url,
                        versionFileOriginalName: last(item.version_file_original_name.split('/')),
                        versionFileSize: item.version_file_size,
                        /*createdAt: get(item, 'created_at'),
                        downloadUrl: item.download_url,
                        id: item.id,
                        modVersion: item.version,
                        versionFileOriginalName: last(item.version_file_original_name.split('/')),
                        versionFileSize: item.version_file_size,*/
                    })),
                },
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processUpdateAccessData = (rawResponseData, state) => {
    try {
        return {
            accessId: rawResponseData.accessData.accessId,
            accessName: rawResponseData.accessData.accessName,
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processModderSectionItems = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    try {
        return rawResponseData.map((rawItem) => {
            const sortedVersions = orderBy(rawItem.versions, ['id'], ['desc'])
            return {
                authorName: rawItem.author_name,
                cover: rawItem.cover_catalog,
                gameVersion: get(sortedVersions, '[0].game_version.version'),
                id: rawItem.id,
                isHidden: rawItem.is_hidden,
                mark: parseFloat(rawItem.mark),
                modVersion: get(sortedVersions, '[0].version'),
                ownerId: rawItem.owner_id, //owner.pk,
                ownerName: rawItem.spa_username, //owner.spa_username,
                tags: getTagsData(rawItem.tags, state),
                title: getModTitle(rawItem, currentLanguageCode), // rawItem.title,
                versions: sortedVersions.map((item) => ({
                    gameVersion: get(item, 'game_version.version'),
                    gameVersionId: get(item, 'game_version.id'),
                    id: item.id,
                    modVersion: item.version,
                    status: item.status,
                    versionFile: item.version_file,
                    versionFileOriginalName: last(item.version_file_original_name.split('/')),
                    versionFileSize: item.version_file_size,
                })),
                state: rawItem.state,
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processModDetailsData = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    // проверка что мод опубликован, и его можно смотреть
    const versionsFilterPredicate = (item) => (
        item.status === MOD_VERSION_STATUS.PUBLISHED &&
        item.is_visible
    )
    const sortedVersions = orderBy(rawResponseData.versions, ['id'], ['desc'])
    sortedVersions.filter(versionsFilterPredicate)
    
    // проверяем если передается не пустой массив с аудио то принимаем его
    if (rawResponseData.voiceovers.length > 0) {
        return {
            authorName: rawResponseData.author_name,
            changelogs: rawResponseData.change_log.map((item) => ({
                modVersion: item.version,
                content: item.body,
            })),
            createdAt: rawResponseData.created_at,
            cover: rawResponseData.cover_catalog, // rawResponseData.cover
            descriptions: rawResponseData.localizations.filter((item) => item.description).map((item) => ({
                language: item.lang, // lang.code
                content: item.description,
            })),
            downloads: rawResponseData.downloads,
            gameVersion: sortedVersions[0].game_version.version,
            installationGuides: rawResponseData.localizations.filter((item) => item.installation_guide).map((item) => ({
                language: item.lang, // lang.code
                content: item.installation_guide,
            })),
            isSubscribed: rawResponseData.is_following,
            isReported: rawResponseData.is_reported,
            mark: parseFloat(rawResponseData.mark),
            markVotesCount: rawResponseData.mark_votes_count,
            modVersion: sortedVersions[0].version,
            ownerId: rawResponseData.owner_id, // owner.pk
            ownerName: rawResponseData.spa_username, // owner.spa_username
            screenshots: rawResponseData.screenshots.map((item) => snakeFieldsToCamel(item)),
            voiceovers: rawResponseData.voiceovers.map((item) => snakeFieldsToCamel(item)),
            //({
            //    id: item.id,
            //    url: item.audio,
            //})),
            tags: getTagsData(rawResponseData.tags, state),
            title: getModTitle(rawResponseData, currentLanguageCode), // rawResponseData.title,
            updatedAt: rawResponseData.updated_at,
            userVote: rawResponseData.user_vote,
            
            versions: sortedVersions.map((item) => ({ // sortedVersions.filter(versionsFilterPredicate).map((item)
                downloadUrl: item.download_url,
                gameVersion: item.game_version.version,
                gameVersionId: item.game_version.id,
                id: item.id,
                modVersion: item.version,
                versionFileOriginalName: last(item.version_file_original_name.split('/')),
                versionFileSize: item.version_file_size,
            })),
        }
    } else {
        return {
            authorName: rawResponseData.author_name,
            changelogs: rawResponseData.change_log.map((item) => ({
                modVersion: item.version,
                content: item.body,
            })),
            createdAt: rawResponseData.created_at,
            cover: rawResponseData.cover_catalog, // rawResponseData.cover
            descriptions: rawResponseData.localizations.filter((item) => item.description).map((item) => ({
                language: item.lang, // lang.code
                content: item.description,
            })),
            downloads: rawResponseData.downloads,
            gameVersion: sortedVersions[0].game_version.version,
            installationGuides: rawResponseData.localizations.filter((item) => item.installation_guide).map((item) => ({
                language: item.lang, // lang.code
                content: item.installation_guide,
            })),
            isSubscribed: rawResponseData.is_following,
            isReported: rawResponseData.is_reported,
            mark: parseFloat(rawResponseData.mark),
            markVotesCount: rawResponseData.mark_votes_count,
            modVersion: sortedVersions[0].version,
            ownerId: rawResponseData.owner_id, // owner.pk
            ownerName: rawResponseData.spa_username, // owner.spa_username
            screenshots: rawResponseData.screenshots.map((item) => snakeFieldsToCamel(item)),
            voiceovers: rawResponseData.voiceovers.map((item) => snakeFieldsToCamel(item)),
            //({
            //    id: item.id,
            //    url: item.audio,
            //})),
            tags: getTagsData(rawResponseData.tags, state),
            title: getModTitle(rawResponseData, currentLanguageCode), // rawResponseData.title,
            updatedAt: rawResponseData.updated_at,
            userVote: rawResponseData.user_vote,
            
            versions: sortedVersions.map((item) => ({ // sortedVersions.filter(versionsFilterPredicate).map((item)
                downloadUrl: item.download_url,
                gameVersion: item.game_version.version,
                gameVersionId: item.game_version.id,
                id: item.id,
                modVersion: item.version,
                versionFileOriginalName: last(item.version_file_original_name.split('/')),
                versionFileSize: item.version_file_size,
            })),
        }
    }
}

export const processModEditDetailsData = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    
    const byLanguagesReducer = (accumulator, item) => {
        accumulator[item.lang] = { // accumulator[item.lang.code]
            description: item.description, // description
            title: item.title,
            installationGuide: item.installation_guide,
        }
        return accumulator
    }
    
    return {
        authorName: rawResponseData.author_name,
        ownerName: rawResponseData.owner_name,
        ownerId: rawResponseData.owner_id,
        downloads: rawResponseData.downloads,
        mark: parseFloat(rawResponseData.mark),
        byLanguages: rawResponseData.localizations.reduce(byLanguagesReducer, {}),
        categories: getTagsData(rawResponseData.tags, state),
        changelog: processChangelogHistoryItems(rawResponseData).changelogForCurrentVersion,
        cover: rawResponseData.cover_catalog,
        isHidden: rawResponseData.is_hidden,
        screenshots: rawResponseData.screenshots.map((item) => ({
            position: item.position,
            screenshotId: item.id,
            source: item.source,
        })),
        voiceovers: rawResponseData.voiceovers.map((item) => ({
            id: item.id,
            voiceoverId: item.id,
            url: item.url, // url = source
            source: item.source,
            title: item.title,
            position: item.position,
        })),
        state: rawResponseData.state,
        status: rawResponseData.status,
        title: getModTitle(rawResponseData, currentLanguageCode),
        versions: orderBy(rawResponseData.versions, ['id'], ['desc']).map((item) => ({
            comment: item.comment,
            createdAt: item.created_at,
            gameVersion: item.game_version.version,
            gameVersionId: item.game_version.id,
            id: item.id,
            isVisible: item.is_visible,
            modVersion: item.version,
            status: item.status,
            versionFile: item.version_file,
            versionFileOriginalName: last(item.version_file_original_name.split('/')),
            versionFileSize: item.version_file_size,
        })),
    }
}

export const processGetUserData = (rawResponseData, state) => {
    //const currentLanguageCode = state.locale.language
    
    return {
        id: rawResponseData.id,
        spaUsername: rawResponseData.spa_username,
        spaId: rawResponseData.spa_id,
        client: rawResponseData.client,
        userName: rawResponseData.username,
        followedMods: rawResponseData.followed_mods,

        birthdayAcc: rawResponseData.birthday_acc, // time lesta
        lastVisit: rawResponseData.last_visit, // time
        role: rawResponseData.role,

        countMods: rawResponseData.count_mods,
        countTickets: rawResponseData.count_tickets,
        countComments: rawResponseData.count_comments,

        readOnly: rawResponseData.read_only, // on OR off
        roExpires: rawResponseData.ro_expires, // time
    }
}

export const processModEditComments = (rawResponseData) => {
    return rawResponseData.map((item) => {
        const result = snakeFieldsToCamel(item)
        result.modVersionId = result.modVersionData.id
        result.modVersion = result.modVersionData.version
        return result
    })
}

export const processModEditVersionAdd = (rawResponseData) => {
    return {
        createdAt: rawResponseData.created_at,
        gameVersion: rawResponseData.game_version_data.version,
        gameVersionId: rawResponseData.game_version_data.id,
        id: rawResponseData.id,
        isVisible: rawResponseData.is_visible,
        modVersion: rawResponseData.version,
        status: rawResponseData.status,
        versionFile: rawResponseData.version_file,
        versionFileOriginalName: last(rawResponseData.version_file_original_name.split('/')),
        versionFileSize: rawResponseData.version_file_size,
    }
}

export const processChangelogHistoryItems = (rawResponseData) => {
    let changelogForCurrentVersion = null
    let changelogForPreviousVersions = rawResponseData.change_log
    const modState = rawResponseData.state
    
    if ([MOD_STATE.DRAFT, MOD_STATE.REJECTED].includes(modState)) {
        const modVersion = rawResponseData.versions[0].version
        changelogForCurrentVersion = changelogForPreviousVersions.find(item => item.version === modVersion) || null
        if (changelogForCurrentVersion) {
            changelogForPreviousVersions = changelogForPreviousVersions.filter(item => item.version !== modVersion)
        }
    }
    
    const result = {
        changelogForPreviousVersions: changelogForPreviousVersions.map((item, index) => ({
            version: item.version,
            content: item.body,
            id: item.id,
            position: index, // было id я назвал position
        })),
        changelogForCurrentVersion: changelogForCurrentVersion ? {
            version: changelogForCurrentVersion.version,
            content: changelogForCurrentVersion.body,
        } : null,
    }
    
    return result
}
