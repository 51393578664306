import { batchActions } from "redux-batched-actions";
import { 
    SET_ERROR_LOGIN, 
    //UPDATE_ACCOUNT, 
    setErrorLogin, 
    updateSpaData, 
    updateSpaDataWithNotify 
} from "./CurrentAccount";
import { fetchWrapper as fetch } from "../utils/fetch";
import urls from "../apiUrls";

export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const openMenu = (count, notifications, isNavigatingToLandingOnClose = false) => ({
    count: count,
    notifications: notifications,
    type: OPEN_MENU,
    isNavigatingToLandingOnClose,
})

export const closeMenu = () => ({
    type: CLOSE_MENU,
})

/* get user data from server lesta id and add it to state */
export const fetchNotifications = (userData) => {
    return (dispatch, getState) => {
        const state = getState();

        const url = `https://tankimods.net`+urls.userNotifications

        //dispatch(startFetching()) //dispatch(startLogin())
        
        const fetchPromise = fetch(url, {method: 'POST', body: userData}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorLogin(rawResponseData.context),
                        //stopFetching(),
                    ], SET_ERROR_LOGIN))
                } else {
                    if (typeof(rawResponseData.notifications) === 'undefined') {
                        /*dispatch(batchActions([
                            updateSpaData(rawResponseData, state),
                            //stopFetching(),
                        ], UPDATE_ACCOUNT))*/
                        dispatch(updateSpaData(rawResponseData, state))
                    } else {
                        /*dispatch(batchActions([
                            updateSpaDataWithNotify(rawResponseData, state),
                            //stopFetching(),
                        ], UPDATE_ACCOUNT))*/
                        dispatch(updateSpaDataWithNotify(rawResponseData, state))
                    }
                }
            })
            .catch((errorData) => {
                dispatch(batchActions([
                    setErrorLogin(errorData),
                    //stopFetching(),
                ], SET_ERROR_LOGIN))
            })
        return fetchPromise // fetchWithSpinner(dispatch, fetchPromise);
    }
}

export const onChangeNotifyActive = (userData) => {
    return (dispatch, getState) => {
        /*const state = getState();

        const url = `https://tankimods.net/api/users/notifications/`
        
        const fetchPromise = fetch(url, {method: 'UPDATE', body: userData}).promise
            .then((rawResponseData) => {
                debugger
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorLogin(rawResponseData.context),
                        //stopFetching(),
                    ], SET_ERROR_LOGIN))
                } else {
                    dispatch(batchActions([
                        updateSpaDataWithNotify(rawResponseData, state),
                        //stopFetching(),
                    ], UPDATE_ACCOUNT))
                }
            })
            .catch((errorData) => {
                dispatch(batchActions([
                    setErrorLogin(errorData),
                    //stopFetching(),
                ], SET_ERROR_LOGIN))
            })
        return fetchPromise*/
    }
}