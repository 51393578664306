import { batchActions } from 'redux-batched-actions';
import { filter } from 'lodash';

import { updateModsCount } from '../actions/CurrentAccount';
//import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import { processSubscriptions } from '../utils/responseProcessing';
import urls from '../apiUrls';
import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';

export const START_FETCHING_SUBSCRIPTIONS = 'START_FETCHING_SUBSCRIPTIONS'
export const SET_ERROR_FETCHING_SUBSCRIPTIONS = 'SET_ERROR_FETCHING_SUBSCRIPTIONS'
export const STOP_FETCHING_SUBSCRIPTIONS = 'STOP_FETCHING_SUBSCRIPTIONS'
export const UPDATE_ITEMS_SUBSCRIPTIONS = 'UPDATE_ITEMS_SUBSCRIPTIONS'

const startFetchingSubscriptions = () => ({
    type: START_FETCHING_SUBSCRIPTIONS,
})

const stopFetchingSubscriptions = () => ({
    type: STOP_FETCHING_SUBSCRIPTIONS,
})

const updateItemsSubscriptions = (items) => ({
    type: UPDATE_ITEMS_SUBSCRIPTIONS,
    payload: {
        items,
    },
})

const setErrorFetchingSubscriptions = (errorData) => ({
    type: SET_ERROR_FETCHING_SUBSCRIPTIONS,
    payload: {
        errorData,
    },
})

export const fetchSubscriptions = (userName) => {
    return (dispatch, getState) => {
        const state = getState()
        
        let url = `https://tankimods.net${urls.subscription}` // urls.subscription
        dispatch(startFetchingSubscriptions())

        const fetchPromise = fetch(url, {method: 'POST', body: userName}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorFetchingSubscriptions(rawResponseData.context),
                        stopFetchingSubscriptions(),
                    ], SET_ERROR_FETCHING_SUBSCRIPTIONS))
                } else {
                    dispatch(batchActions([
                        updateItemsSubscriptions(processSubscriptions(rawResponseData, state)),
                        stopFetchingSubscriptions(),
                    ], UPDATE_ITEMS_SUBSCRIPTIONS))
                    dispatch(updateModsCount(filter(rawResponseData, (item) => item.is_updated).length))
                }
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingSubscriptions(errorData),
                    stopFetchingSubscriptions(),
                ], SET_ERROR_FETCHING_SUBSCRIPTIONS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

const following = (modId, method, userName, id) => {
    return (dispatch) => {
        let body, url
        if (method === 'POST' || method === 'DELETE') {
            url = `https://tankimods.net${urls.subscriptionAction}` //`${urls.subscriptionAction}`
            body = {
                is_updated: false,
                mod: modId,
                spaUsername: userName.spaUsername,
                spaId: userName.spaId,
            }
        } else {
            url = `https://tankimods.net`+urls.subscriptionAction+`?id=${id}/` //`${urls.subscriptionAction}${id}/`
            body = {
                id: id,
                is_updated: false,
                mod: modId,
                spaUsername: userName.spaUsername,
                spaId: userName.spaId,
            }
        }
        const fetchPromise = fetch(url, { method, body }).promise
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const unsubscribeMod = (modId, userName) => {
    return following(modId, 'DELETE', userName)
}

export const ignoreModUpdate = (modId, userName, id) => {
    return following(modId, 'PUT', userName, id)
}

export const followMod = (modId, userName) => {
    return following(modId, 'POST', userName)
}
